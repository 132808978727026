.header-container {
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 10;
}

.header {
  background: #41b496;
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.header-dark {
  background: #191919;
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-bottom: 1px solid;
}

.header-wrapper {
  width: 85%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.logo-wrapper {
  height: fit-content;
}

.header-actions {
  margin-right: 84px;
  width: 160px;
  display: flex;
  justify-content: space-between;
  height: fit-content;
}

.header-icon {
  height: 30px;
}

.search-icon {
  height: 18px;
}

img {
  margin: 0px !important;
}

.detailspage-search-container {
  width: 100%;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid grey;
  background: #ffffff;
}

.detailspage-search-container-dark {
  width: 100%;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid grey;
  background: #4b4b4b;
}

.detailspage-search {
  display: flex;
  align-items: center;
  width: 60%;
  background: #ffffff;
}

.detailspage-search-dark {
  display: flex;
  align-items: center;
  width: 60%;
  background: #4b4b4b;
}

.detailspage-input {
  background: #ffffff;
  height: 50px;
  border: none;
  font-size: 18px;
  color: #191919;
  font-family: "Source Code Pro";
  width: 100%;
}

.detailspage-input-dark {
  background: #4b4b4b !important;
  height: 50px;
  border: none;
  font-size: 18px;
  color: #ffffff;
  width: 100%;
  font-family: "Source Code Pro";
}

.detailspage-input-dark:-webkit-autofill,
.detailspage-input-dark:-webkit-autofill:hover,
.detailspage-input-dark:-webkit-autofill:focus,
.detailspage-input-dark:-webkit-autofill:active {
  background: #4b4b4b !important;
}

.detailspage-input-dark::placeholder {
  color: #ffffff !important;
  font-size: 18px;
  font-family: "Source Code Pro";
}

.detailspage-input::placeholder {
  color: #707070 !important;
  font-size: 18px;
  font-family: "Source Code Pro";
}

.body-dark {
  background: #191919;
  z-index: -200;
}

.body {
  background: #ffffff;
  z-index: -500;
}

@media only screen and (max-width: 520px) {
  .header-wrapper {
    width: 96%;
  }
  .header-actions {
    margin-right: 10px;
  }
  .header-logo {
    height: 45px;
  }
  .header-icon {
    height: 25px;
  }
  .header-actions {
    width: 120px;
  }
  .detailspage-input-dark::placeholder {
    font-size: 16px;
  }

  .detailspage-input::placeholder {
    font-size: 16px;
  }
}
