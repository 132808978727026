.section {
  background: #ffffff;
}
.section-dark {
  background: #191919;
}
.log-page {
  width: 50%;
  margin: 0 auto;
  padding-top: 80px;
  background: #ffffff;
  padding-top: 200px;
}

.log-page-dark {
  width: 50%;
  margin: 0 auto;
  padding-top: 80px;
  background: #191919;
  padding-top: 200px;
}

.log-header {
  color: hsla(0, 0%, 0%, 0.8);
  font-size: 50px;
  margin: 0px;
}

.log-header-dark {
  color: #ffffff;
  font-size: 50px;
  margin: 0px;
}

.log-text {
  color: #646464;
  font-size: 1em;
  line-height: 1.714;
}

.log-text-dark {
  color: #ffffff;
  font-size: 1em;
  line-height: 1.714;
}

.log-divider {
  width: 100%;
  height: 1px;
  background-color: #707070;
  margin-bottom: 50px;
}

a {
  text-decoration: none;
  color: unset;
}

.log {
  padding-bottom: 40px;
}

.share-icon {
  margin-right: 8px !important;
  cursor: pointer;
}

.log-title {
  display: flex;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin: 0px;
  position: relative;
}

.copiedLink {
  position: absolute;
  top: -20px;
  left: -21px;
  background: #060606;
  color: #ffff;
  padding: 2px 5px;
  font-size: 12px;
  display: none;
  z-index: 2;
}

.copiedLink-active {
  display: inline-block;
}

.copyLink {
  position: absolute;
  top: -20px;
  left: -21px;
  background: #060606;
  color: #ffff;
  padding: 2px 5px;
  font-size: 12px;
  z-index: 1;
  display: none;
}

.copyLink-active {
  display: inline-block;
}

.copy-code {
  position: absolute;
  z-index: 2;
  background: #060606;
  color: #ffff;
  padding: 2px 5px;
  font-size: 12px;
  right: 5px;
  top: -20px;
  z-index: 1;
  visibility: hidden;
}

.copy-code-active {
  visibility: visible;
}

.copied-code {
  position: absolute;
  z-index: 2;
  background: #060606;
  color: #ffff;
  padding: 2px 5px;
  font-size: 12px;
  right: 5px;
  top: -20px;
  visibility: hidden;
  z-index: 1;
}

.copied-code-active {
  visibility: visible;
}

.log-title-wrapper {
  width: 100%;
  height: fit-content;
  background: #323232;
  display: flex;
  align-items: center;
  padding: 15px;
}

.log-title-wrapper-dark {
  width: 100%;
  height: fit-content;
  background: #f0f0f0;
  display: flex;
  align-items: center;
  padding: 15px;
}

.log-name {
  color: #ffffff;
}

.log-name-dark {
  color: #191919;
}
.log-content {
  margin-left: 40px;
  margin-top: 10px;
  color: #323232;
  line-height: 22px;
}

.log-content-dark {
  margin-left: 40px;
  margin-top: 10px;
  color: #ffffff;
  line-height: 22px;
}

li {
  padding: 5px 0px;
}

.log-notes {
  margin-left: 50px;
  margin-top: 10px;
  margin-bottom: 0px;
  color: #323232;
}

.log-notes-dark {
  margin-left: 50px;
  margin-top: 10px;
  margin-bottom: 0px;
  color: #ffffff;
}

code {
  font-size: 0.875em;
  font-family: SFMono-Medium, "SF Mono", "Segoe UI Mono", "Roboto Mono",
    "Ubuntu Mono", Menlo, Consolas, Courier, monospace;
  font-weight: normal;
  background-color: var(--ds--code--bg-color, #f4f5f7);
  color: rgb(23, 43, 77);
  border-style: none;
  border-radius: 3px;
  display: inline;
  padding: 2px 0.5ch;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  overflow: auto;
  margin: 0px 4px;
  white-space: pre;
}

.code-snippets {
  list-style: none;
  margin-left: 50px;
  color: #323232;
}

.code-snippets-dark {
  list-style: none;
  margin-left: 50px;
  color: #ffffff;
}

.extra-notes {
  margin-left: 110px;
  color: #191919;
}
.extra-notes-dark {
  color: #ffffff;
  margin-left: 110px;
}

.ahref-img {
  height: 14px;
  width: 14px;
  margin-right: 4px;
  border-radius: 2px;
  user-select: none;
}

.ahref-img-wrapper {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.inline-link {
  color: #0052cc;
}
.inline-link:hover {
  text-decoration: underline;
  color: #0052cc;
}

.logs-page {
  background: #ffffff;
}

.code-snippet {
  width: 91%;
  display: grid;
  grid-template-columns: 4% 96%;
  border-radius: 3px;
  background-color: var(--ds--code--bg-color, #f4f5f7);
  margin-left: 70px;
  margin-top: 20px;
  max-width: 91%;
}
.code-snippet li {
  height: 18px;
}
.codelines {
  margin-left: 8px;
  position: relative;
}

.codelinesnum,
.codelines {
  list-style-type: none;
  margin-block-start: 0px;
  margin-block-end: 0px;
  padding-left: 0px;
}

.copyicon {
  height: 20px;
  padding: 2px;
  z-index: 200;
}

.copy-button {
  border: none;
  background: none;
  position: absolute;
  right: 5px;
  top: 5px;
  cursor: pointer;
  display: hidden;
}

.copy-button-singleline {
  border: none;
  background: none;
  display: flex;
}

.codelines-singleline {
  display: flex;
  list-style-type: none;
  margin-block-start: 0px;
  margin-block-end: 0px;
  padding-left: 0px;
  margin-left: 8px;
  position: relative;
  overflow-x: auto;
}

/* .copyicon:hover {
  background-color: lightgrey;
} */

.codelinesnum {
  color: rgb(80, 95, 121) !important;
  font-size: 12px;
  padding: 0px;
  background-color: #ececf0;
}

.codelinesnum li {
  text-align: center;
  font-size: 1.25em;
}

.codelinesnum code {
  background: none;
  background-color: #ececf0;
}

@media only screen and (max-width: 1024px) {
  .log-page-dark {
    width: 70%;
  }
}

@media only screen and (max-width: 768px) {
  .log-page-dark {
    width: 80%;
  }
}

@media only screen and (max-width: 520px) {
  .log-page-dark,
  .log-page {
    width: 80%;
  }
  .log-header-dark,
  .log-header {
    font-size: 25px;
  }
  .section-dark,
  .section {
    width: 100%;
  }
  .detailspage-search,
  .detailspage-search-dark {
    width: 100%;
  }
  .log-page-dark,
  .log-page {
    padding-top: 160px;
  }
  .log-title-wrapper-dark,
  .log-title-wrapper {
    padding: 7px;
  }
  .log-notes,
  .log-notes-dark,
  .code-snippets {
    margin-left: 25px;
  }
  .extra-notes {
    margin-left: 50px;
  }
  .code-snippet {
    grid-template-columns: 11% 89%;
    width: 81%;
    margin-left: 50px;
  }
  code {
    display: block;
  }
  .code-snippet {
    overflow-x: auto;
  }
  .copy-code {
    display: none;
  }
  .copy-code-active {
    display: none;
  }
  .copy-button {
    display: none;
  }
}

::-webkit-scrollbar {
  height: 4px;
  width: 4px;
  background: #ffffff;
}
::-webkit-scrollbar-thumb:horizontal {
  background: #41b496;
  border-radius: 10px;
}
