@import url("https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@300&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Livvic:wght@100;400;500;600;700&family=Source+Code+Pro:wght@300&display=swap");

.landing-section {
  position: relative;
  background: #ffffff;
  padding-top: 50px;
}

.landing-section-dark {
  position: relative;
  background: #191919;
  padding-top: 50px;
}

.landing-header {
  color: #005aaa;
  font-weight: 700;
  font-size: 70px;
  z-index: 0;
  font-family: "Livvic";
  margin-bottom: 0px;
}

.landing-header-dark {
  color: #ffffff;
  font-weight: 700;
  font-size: 70px;
  z-index: 0;
  font-family: "Livvic";
  margin-bottom: 0px;
}

.landing-copy {
  width: 60%;
  margin: 0 auto;
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.landing-text {
  color: #005aaa;
  font-family: "Livvic";
  font-size: 30px;
  text-align: left;
  line-height: 44px;
  margin-bottom: 55px;
  font-weight: 400;
  z-index: 2;
}

.landing-text-dark {
  color: #fff;
  font-family: "Livvic";
  font-size: 30px;
  text-align: left;
  line-height: 44px;
  margin-bottom: 55px;
  z-index: 2;
  font-weight: 400;
}

.landing-log-message {
  position: absolute;
  top: -15px;
  background: #ffff;
  left: 40px;
  color: red;
  font-size: 14px;
  letter-spacing: 0.5px;
}

textarea:focus,
input:focus {
  outline: none;
}

.landing-search {
  width: 61%;
  margin: 0 auto;
  border: 1px solid #646464;
  border-radius: 50px;
  display: flex;
  align-items: center;
  background: #ffffff;
  z-index: 200;
}

.landing-search-dark {
  width: 61%;
  margin: 0 auto;
  border: 1px solid #646464;
  border-radius: 50px;
  display: flex;
  align-items: center;
  background: #ffffff;
}

.landing-searchIcon {
  height: 20px;
  margin: 0px 8px !important;
}

.landing-input {
  border: none;
  width: 90%;
  height: 40px;
  background: #ffffff;
  color: #191919;
  height: 50px;
  font-size: 20px;
  color: #191919;
  font-family: "Source Code Pro";
}

.landing-input::-webkit-input-placeholder {
  font-size: 20px;
  color: #707070;
  font-family: "Source Code Pro";
}

.landing-input-dark {
  border: none;
  width: 90%;
  z-index: 2;
  height: 40px;
  background: #ffffff;
  color: #ffffff;
  height: 50px;
  font-size: 20px;
  color: #191919;
  font-family: "Source Code Pro";
}

.landing-input-dark::placeholder {
  color: #707070;
  font-size: 20px;
  font-family: "Source Code Pro";
}

.active-placeholder {
  height: 100%;
  line-height: 29px;
  z-index: 1;
  position: absolute;
  margin-left: 2px;
  width: 100%;
  bottom: -200%;
  cursor: pointer;
}

.landing-menu {
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
  width: 100%;
  padding: 80px 0px;
  justify-content: center;
  align-items: center;
}

.landing-menu_item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 250px;
  padding: 30px 20px 30px 20px;
  height: 400px;
  border: 1px solid #c9c9c9;
  z-index: 2;
  background: #fff;
}

.landing-menu_item-dark {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 250px;
  padding: 30px 20px 30px 20px;
  height: 400px;
  border: 1px solid #c9c9c9;
  background: #323232;
  z-index: 2;
}

hr {
  width: 60%;
}

.menu_item-logo {
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
}

.menu_item-topic {
  text-align: center;
  font-size: 24px;
  margin: 20px 0px;
  color: #005aaa;
  font-weight: bold;
}

.menu_item-topic-dark {
  text-align: center;
  font-size: 24px;
  margin: 20px 0px;
  color: #41b496;
  font-weight: bold;
}

.menu_item-description {
  text-align: center;
  font-size: 17px;
  line-height: 23px;
  margin: 20px 0px;
  line-height: 20px;
  width: 80%;
  color: #323232;
  height: 100px;
}

.menu_item-description-dark {
  text-align: center;
  font-size: 17px;
  line-height: 23px;
  margin: 20px 0px;
  line-height: 20px;
  width: 80%;
  color: #e1e1e1;
  height: 100px;
}

.menu_item-btn {
  width: 70%;
  height: 40px;
  background-color: #69c8af;
  color: #fff;
  font-size: 14px;
  border: none;
  cursor: pointer;
  margin-top: 20px;
}

.menu_item-btn:hover {
  background-color: #4b4b4b;
}

.menu_item-btn-dark {
  width: 70%;
  height: 40px;
  background-color: #191919;
  color: #fff;
  font-size: 14px;
  border: none;
  cursor: pointer;
  margin-top: 20px;
}

.menu_item-btn-dark:hover {
  background-color: #41b496;
}

.menu_item-btn {
  width: 70%;
  height: 40px;
  background-color: #69c8af;
  color: #fff;
  font-size: 14px;
  border: none;
  cursor: pointer;
}

.redirect-icon {
  width: 80px;
  height: 80px;
  object-fit: contain;
  margin: 0 auto;
}

@media only screen and (max-width: 1024px) {
  .landing-search,
  .landing-copy {
    width: 70%;
  }
}

@media only screen and (max-width: 768px) {
  .landing-copy {
    width: 80%;
  }
  .landing-header,
  .landing-header-dark {
    font-size: 60px;
    margin-bottom: 20px;
    text-align: center;
  }
  .landing-text-dark,
  .landing-text {
    font-size: 24px;
    line-height: 30px;
    text-align: center;
  }
  .landing-search {
    display: flex;
    align-items: center;
    width: 100%;
    position: fixed;
    top: 80px;
    border-radius: 0px;
    background-color: #ffffff;
    border: none;
    border-bottom: 1px solid #4b4b4b;
  }
  .landing-search-dark {
    width: 100%;
    position: fixed;
    top: 81px;
    border-radius: 0px;
    background: #4b4b4b;
    z-index: 200;
  }
  .landing-menu_item-dark,
  .landing-menu_item {
    width: 150px;
  }
  .landing-input-dark {
    background: #4b4b4b;
  }

  .landing-input {
    font-size: 16px;
  }

  .landing-input::-webkit-input-placeholder {
    font-size: 16px;
  }

  .landing-input-dark {
    font-size: 16px;
    color: #ffffff;
  }

  .landing-input-dark::placeholder {
    font-size: 16px;
    color: #ffffff;
  }
}

@media only screen and (max-width: 520px) {
  .landing-header-dark,
  .landing-header {
    font-size: 38px;
    padding-top: 30px;
  }
  .landing-text-dark,
  .landing-text {
    font-size: 23px;
    line-height: 28px;
    margin-bottom: 0px;
  }
  .landing-menu_item-dark,
  .landing-menu_item {
    width: 60%;
    height: 400px;
  }
  .menu_item-topic-dark,
  .menu_item-topic,
  .menu_item-description-dark,
  .menu_item-description {
    height: 80px;
  }
  .landing-footer_feedback-dark,
  .landing-footer_feedback {
    align-items: baseline;
    height: 450px;
  }
}
