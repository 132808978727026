.sidebar {
  position: fixed;
  width: 250px;
  height: 400px;
  background-color: #f0f0f0;
  top: 200px;
  left: 0px;
  z-index: 30;
}

.sidebar-dark {
  position: fixed;
  width: 250px;
  height: 400px;
  background-color: #646464;
  top: 200px;
  left: 0px;
  z-index: 30;
}

.sidebar-close {
  position: absolute;
  left: 260px;
  cursor: pointer;
  background: #646464;
  padding: 3px;
  border-radius: 2px;
  height: 13px;
}

.sidebar-close-dark {
  position: absolute;
  left: 260px;
  cursor: pointer;
  background: #41b496;
  padding: 3px;
  border-radius: 2px;
  height: 13px;
}

.toggler-icon {
  position: fixed;
  top: 200px;
  left: 0px;
  cursor: pointer;
  z-index: 30;
}

.toggler-icon img {
  height: 100px;
}

.sidebar-content {
  margin: 20px;
  height: 360px;
  overflow: auto;
}

.sidebar-topic {
  font-weight: 600;
  font-size: 18px;
  margin: 20px 0px;
  letter-spacing: 0.5;
  color: #191919;
}

.sidebar-topic-dark {
  font-weight: 600;
  font-size: 18px;
  margin: 20px 0px;
  letter-spacing: 0.5;
  color: #f0f0f0;
}

.sidebar-options {
  font-weight: 400;
  font-size: 15px;
  cursor: pointer;
  color: #0000ee;
  letter-spacing: 0.5;
  line-height: 25px;
}

.sidebar-options-dark {
  font-weight: 400;
  font-size: 15px;
  cursor: pointer;
  color: #41b496;
  letter-spacing: 0.5;
  line-height: 25px;
}

.sidebar-options-dark:hover {
  color: #41b496;
  text-decoration: underline;
}

::-webkit-scrollbar {
  width: 5px;
  background-color: white;
}
::-webkit-scrollbar-thumb {
  background: #41b496;
  border-radius: 10px;
}

.sidebar-options:hover {
  border-bottom: 1px solid #0000ee;
}

@media only screen and (max-width: 520px) {
  .sidebar,
  .sidebar-dark {
    top: 134px;
    width: 290px;
  }
  .sidebar-close,  .sidebar-close-dark {
    left: 293px;
  }
}
