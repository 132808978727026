.landing-footer_feedback {
  display: flex;
  align-items: baseline;
  justify-content: center;
  width: 100%;
  height: 320px;
  background-color: #69c8af;
  padding-top: 60px;
}

.landing-footer_feedback-dark {
  display: flex;
  align-items: baseline;
  justify-content: center;
  width: 100%;
  height: 320px;
  background-color: #323232;
  padding-top: 60px;
}

.footer_feedback-content {
  margin: 0 auto;
  width: 700px;
  padding: 0px 15px;
  color: #fff;
  position: relative;
}

.footer_feedback-content h1 {
  font-size: 40px;
}

.footer_feedback-content h3 {
  font-size: 28px;
  font-weight: normal;
}

.feedback-content_btn {
  color: #ffffff;
  border: none;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
  height: 46px;
  width: 180px;
  background-color: #41b496;
  display: flex;
  justify-content: center;
  align-items: center;
}

.feedback-content_btn-dark {
  color: #ffffff;
  border: none;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
  height: 46px;
  width: 180px;
  background-color: #41b496;
  display: flex;
  justify-content: center;
  align-items: center;
}

.feedback-content_btn:hover {
  color: #69c8af;
  border: none;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.6);
}

.feedback-content_btn-dark:hover {
  color: #f0f0f0;
  border: none;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.6);
}

.feedback_link-img {
  position: absolute;
  height: 180px;
  right: -40px;
  bottom: -60px;
}

.landing-footer_socials {
  background: #41b496;
  padding: 50px 0px;
  display: flex;
  justify-content: center;
}

.landing-footer_socials-dark {
  background: #191919;
  padding: 50px 0px;
  display: flex;
  justify-content: center;
}

.footer-wrapper {
  display: flex;
  justify-content: space-between;
  width: 45%;
}

.contact,
.socials {
  color: #ffffff;
  margin: 0px 0px 8px 0px;
}

.link {
  color: #ffffff;
  font-size: 13px;
  line-height: 15px;
}

.social-icons {
  display: flex;
}

.footer-social-icon {
  max-height: 20px;
  width: auto;
  padding-right: 8px;
  cursor: pointer;
}

.feedback-content-link {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.feedback-content-btn-text {
  margin-right: 6px;
  font-size: 16px;
}

@media only screen and (max-width: 1024px) {
  .footer-wrapper {
    width: 70%;
  }
}

@media only screen and (max-width: 768px) {
  .feedback_link-img {
    right: 76px;
  }
  .footer-wrapper {
    width: 90%;
  }
}

@media only screen and (max-width: 520px) {
  .footer_feedback-content h1 {
    font-size: 30px;
  }
  .footer_feedback-content {
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .footer_feedback-content h3 {
    font-size: 20px;
    text-align: center;
  }
  .footer_feedback-content h1 {
    text-align: center;
    margin-top: 0px;
  }
  .feedback_link-img {
    height: 120px;
    right: 6px;
    top: 300px;
  }
  .footer-wrapper {
    display: flex;
    height: 243px;
    flex-direction: column;
    align-items: center;
    width: 76%;
  }
  .contact-wrapper,
  .socials-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .footer_feedback-content button {
    margin-top: 25px;
  }
}
